// https://storybook.js.org/docs/react/configure/theming#create-a-theme-quickstart
import { create } from '@storybook/theming';

export default create({
  // This property is required.
  base: 'light',
  // Main colors.
  colorPrimary: '#3b61eb',
  colorSecondary: '#7680a3',
  // UI.
  appBg: '#f0f4fa',
  appContentBg: '#fff',
  appBorderColor: '#6d81a6',
  appBorderRadius: 4,
  // Typography.
  fontBase: '"Proxima Nova", sans-serif',
  fontCode: 'monospace',
  // Text colors.
  textColor: '#2f426c',
  textInverseColor: '#dde5f5',
  textMutedColor: '#7680a3',
  // Toolbar default and active colors.
  barTextColor: '#3b61eb',
  barSelectedColor: '#2347cb',
  barBg: '#fff',
  // Form colors.
  inputBg: '#e5ecf6',
  inputBorder: '#6d81a6',
  inputTextColor: '#2f426c',
  inputBorderRadius: 4,
  // Brand props.
  brandTitle: 'WalkMe',
  brandUrl: 'https://www.walkme.com/',
  brandImage: 'walkme-logo.svg',
});
